import React from "react";
import styled from "styled-components";
import { darken } from "polished";
import { Twitter as TwitterIcon } from "react-feather";
import { TYPE } from "theme";
import { ExternalLink } from "../../theme/components";
import useTwitterIntent from "hooks/useTwitterIntent";

export const Break = styled.div`
  width: 800px;
  background-color: ${({ theme }) => theme.bg3};
  height: 1px;
  margin: 0;
`;

const RoundedLinkTweetintent = styled(ExternalLink)`
  padding: 0 12px 0 12px;
  background-color: ${({ theme }) => theme.primary1};
  max-height: 65px;
  text-decoration: none;
  border-radius: 4px;
  border: 1px solid;
  border-color: ${({ theme }) => theme.primary1};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;
  ${({ theme }) => theme.mediaWidth.upToSmall`
     width: 100%;
  `}
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
     min-width: fit-content;
  `}
  :hover {
    text-decoration: none;
    background-color: ${({ theme }) => theme.secondary1};
    color: ${({ theme }) => theme.primary1};
  }
  :focus {
    text-decoration: none;
    outline-style: solid;
    outline-color: ${({ theme }) => darken(0.05, theme.secondary1)};
  }
  :active {
    transform: scale(0.95) translateY(4px);
  }
`;

export default function TwitterIntentButton(props: {
  overrideReferralLink?: string;
}) {
  console.log(props.overrideReferralLink);
  const twitterIntentUrl = useTwitterIntent(
    props.overrideReferralLink ?? undefined,
  );

  return (
    <>
      {twitterIntentUrl && (
        <RoundedLinkTweetintent href={twitterIntentUrl} color='white'>
          <TYPE.custom fontSize='0.825rem' fontWeight={400} marginRight='8px'>
            Tweet your unique link
          </TYPE.custom>
          <TwitterIcon size={20} fill='#fff' />
        </RoundedLinkTweetintent>
      )}
    </>
  );
}
