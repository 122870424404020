import config from "config";
import {
  convertExcelResponseToAirdropList,
  fetchListExcel,
} from "data/utils/excel";
import { useActiveWeb3React } from "hooks";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AppState } from "state";
import { useVerifiedHandle } from "state/social/hooks";
import { updateAmplifiAirdropList } from "state/user/actions";
export function useAirdrop() {
  const { account } = useActiveWeb3React();
  const verifiedHandleEntry = useVerifiedHandle(account);
  const dispatch = useDispatch();
  const airdropList = useSelector<AppState, AppState["user"]["amplifiAirdrop"]>(
    (state) => {
      return state.user.amplifiAirdrop;
    },
    shallowEqual,
  );
  useEffect(() => {
    if (account && verifiedHandleEntry) {
      fetchListExcel(
        config.airdrop.excel.id,
        "example",
        "airdrop",
        undefined,
      ).then((res) => {
        if (res) {
          dispatch(
            updateAmplifiAirdropList({
              amplifiAirdrop: convertExcelResponseToAirdropList(res),
            }),
          );
        }
      });
    }
  }, [account, verifiedHandleEntry, dispatch]);
  return (
    verifiedHandleEntry &&
    verifiedHandleEntry.handle &&
    airdropList &&
    airdropList[verifiedHandleEntry.handle]
  );
}

export default useAirdrop;
