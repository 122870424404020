import { useMemo } from "react";
import { useActiveCampaign, useReferralLink } from "state/campaigns/hooks";

import getTextToTwitter from "utils/getTextToTwitter";

export default function useTwitterIntent(overrideReferralLink?: string) {
  const [activeCampaign] = useActiveCampaign();
  const referralLink = useReferralLink();
  const twitterIntentUrl = useMemo(() => {
    if (activeCampaign?.tweetIntent && referralLink) {
      return getTextToTwitter(
        activeCampaign.tweetIntent,
        overrideReferralLink || referralLink,
      );
    }
    return undefined;
  }, [activeCampaign?.tweetIntent, referralLink, overrideReferralLink]);

  return twitterIntentUrl;
}
