import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";
import config from "config";
export const GMXReferralClient = new ApolloClient({
  link: new HttpLink({
    uri: config.api.referralSubgraphs.gmx, // what should i put in here?
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
});

export const CustomGMXReferralClient = new ApolloClient({
  link: new HttpLink({
    uri: config.api.referralSubgraphs.customGmx, // what should i put in here?
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
});
