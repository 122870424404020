import React, { useState, useEffect } from "react";
import { AutoColumn } from "../Column";
import { useActiveProtocol } from "../../state/governance/hooks";
import EmptyProfile from "../../assets/images/emptyprofile.png";
import { useToggleModal } from "../../state/application/hooks";
import { useActiveWeb3React, useTheme } from "../../hooks";
import ReactGA from "react-ga";
import { ApplicationModal } from "../../state/application/actions";
import Card, { WhiteCard } from "../Card";
import { RowFixed, RowBetween, RowFlat, Row } from "../Row";
import { TYPE, BlankInternalLink, Above1080Only } from "../../theme";
import { ButtonBasic, ButtonCustom } from "../Button";
import { shortenAddress } from "../../utils";
import {
  useVerifiedHandle,
  useTwitterProfileData,
} from "../../state/social/hooks";
import { useTwitterAccount } from "../../state/user/hooks";
import useParsedQueryString from "../../hooks/useParsedQueryString";
import Modal from "../Modal";
import TwitterFlow from "../twitter/TwitterFlow";
import TwitterLoginButton from "../twitter/TwitterLoginButton";
import TwitterIcon from "../../assets/images/Twitter_Logo_Blue.png";
import LogoText from "../governance/LogoText";
import { lighten } from "polished";
import VoteContent from "./VoteContent";
import WalletSummary from "./WalletSummary";
import MysteryAmplifiCard from "components/MysteryAmplifiCard";
import {
  SectionWrapper,
  BackgroundWrapper,
  TwitterButton,
  TwitterLogo,
  AirdropGreyBox,
  MobileWrapper,
  RoundedProfileImage,
  ButtonText,
} from "./StyledProfile";

export default function Profile() {
  const theme = useTheme();

  // account details
  const { account, chainId } = useActiveWeb3React();
  const [activeProtocol] = useActiveProtocol();

  // UI views
  const toggleWalletModal = useToggleModal(ApplicationModal.WALLET);

  // toggle modal for twitter verification
  const [showTwitterFlow, setShowTwitterFlow] = useState<boolean>(false);

  // get any verified handles for this user + timestamps they were created at
  const [twitterAccount] = useTwitterAccount(); // logged in account
  const verifiedHandleEntry = useVerifiedHandle(account);

  const loadingVerifiedHandles = verifiedHandleEntry === null;
  const profileData = useTwitterProfileData(verifiedHandleEntry?.handle);

  // on redirect from twitter, if signed in, not verified, and no loading, show modal
  const [loaded, setLoaded] = useState(false);
  const { username: usernameQuery } = useParsedQueryString();
  useEffect(() => {
    if (
      twitterAccount &&
      !verifiedHandleEntry &&
      !loadingVerifiedHandles &&
      !loaded &&
      usernameQuery
    ) {
      setShowTwitterFlow(true);
      setLoaded(true);
    }
  }, [
    loadingVerifiedHandles,
    twitterAccount,
    loaded,
    usernameQuery,
    verifiedHandleEntry,
  ]);

  // toggle for mobile view
  const [showProfileModal, setShowProfileModal] = useState(false);

  const ProfileContent = () => (
    <SectionWrapper>
      <BackgroundWrapper
        account={!!account}
        bgColor={
          account
            ? lighten(
                "0.01",
                activeProtocol?.secondaryColor
                  ? activeProtocol.secondaryColor
                  : theme.bg2,
              )
            : theme.bg2
        }
      >
        {!account ? (
          <Above1080Only>
            <TYPE.body
              fontWeight={500}
              fontSize='14px'
              color={activeProtocol?.primaryColor}
              mb='1rem'
            >
              Connect your wallet to interact with the app
            </TYPE.body>
            {activeProtocol && (
              <ButtonCustom
                color={activeProtocol?.primaryColor}
                bgColor={activeProtocol?.secondaryColor}
                style={{
                  fontWeight: 500,
                  fontSize: "16px",
                }}
                onClick={() => toggleWalletModal()}
              >
                Connect Wallet
              </ButtonCustom>
            )}
          </Above1080Only>
        ) : (
          <AutoColumn gap='16px'>
            {!verifiedHandleEntry && account ? (
              !twitterAccount ? (
                <WhiteCard>
                  <RowFlat>
                    <MysteryAmplifiCard width={"140px"} />
                    <div style={{ width: "5px" }} />
                    <TwitterLoginButton text='Add a public identity' />
                  </RowFlat>
                  Connect your twitter to participate in some campaigns
                </WhiteCard>
              ) : (
                <TwitterButton
                  onClick={() => {
                    ReactGA.event({
                      category: "Social",
                      action: "Start Link",
                      label: "Not linked",
                    });
                    setShowTwitterFlow(true);
                  }}
                >
                  <RowBetween>
                    <TYPE.white fontSize='14px'>
                      Add a public identity
                    </TYPE.white>
                    <TwitterLogo src={TwitterIcon} />
                  </RowBetween>
                </TwitterButton>
              )
            ) : null}
            {account && verifiedHandleEntry && (
              <WhiteCard>
                <RowFlat>
                  <MysteryAmplifiCard />
                  <AirdropGreyBox />
                  <AirdropGreyBox />
                </RowFlat>
              </WhiteCard>
            )}
            <WalletSummary />
            <TYPE.main mb='16px'>
              Your{" "}
              <span style={{ color: activeProtocol?.primaryColor }}>
                {" "}
                {activeProtocol?.name}
              </span>{" "}
              breakdown
            </TYPE.main>
            <VoteContent />
          </AutoColumn>
        )}
      </BackgroundWrapper>
    </SectionWrapper>
  );

  return (
    <>
      <Modal
        isOpen={showTwitterFlow}
        onDismiss={() => setShowTwitterFlow(false)}
      >
        <TwitterFlow onDismiss={() => setShowTwitterFlow(false)} />
      </Modal>
      <MobileWrapper>
        <Modal
          isOpen={showProfileModal}
          onDismiss={() => setShowProfileModal(false)}
        >
          <ProfileContent />
        </Modal>
        <Card padding='10px'>
          <RowBetween>
            <BlankInternalLink
              to={`/delegates/${activeProtocol?.id}/${account}`}
            >
              <Row>
                <RoundedProfileImage>
                  <img
                    src={
                      !profileData?.profileURL
                        ? EmptyProfile
                        : profileData?.profileURL
                    }
                  />
                </RoundedProfileImage>
                {!account ? (
                  <TYPE.main>Your Address</TYPE.main>
                ) : (
                  chainId &&
                  (verifiedHandleEntry?.handle ? (
                    <AutoColumn gap='4px'>
                      <LogoText type='twitter'>
                        @{verifiedHandleEntry.handle}
                      </LogoText>
                      <TYPE.main fontSize='12px'>
                        {shortenAddress(account)}
                      </TYPE.main>
                    </AutoColumn>
                  ) : (
                    <TYPE.main mr='10px' color={theme.text1}>
                      {shortenAddress(account)}
                    </TYPE.main>
                  ))
                )}
              </Row>
            </BlankInternalLink>
            {!account ? (
              <ButtonBasic width='fit-content' onClick={toggleWalletModal}>
                <ButtonText>Connect wallet</ButtonText>
                {/* <ConnectPitch stageText="Wallet and Twitter" /> */}{" "}
                {/* I'm not sure if we need this! */}
              </ButtonBasic>
            ) : (
              <RowFixed>
                <ButtonCustom
                  onClick={() => setShowProfileModal(true)}
                  bgColor={activeProtocol?.secondaryColor}
                >
                  <TYPE.body
                    color={activeProtocol?.primaryColor}
                    fontWeight={500}
                  >
                    Details
                  </TYPE.body>
                </ButtonCustom>
              </RowFixed>
            )}
          </RowBetween>
        </Card>
      </MobileWrapper>
    </>
  );
}
