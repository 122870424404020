import styled from "styled-components";
import { TYPE } from "theme";
import { ButtonBasic } from "components/Button";

export const SectionWrapper = styled.div`
  width: 100%;
  padding: 1rem;
  padding-top: 2rem;
  height: 100%;

  @media (max-width: 1080px) {
    padding: 0;
  }
`;

export const BackgroundWrapper = styled.div<{
  bgColor?: string;
  account: boolean;
}>`
  background: ${({ theme, bgColor, account }) =>
    !account
      ? theme.bg2
      : `linear-gradient(180deg, ${bgColor} 0%, ${theme.bg1} 100%);`};
  padding: 1rem;
  height: ${({ account }) => (account ? "100%" : "initial")};
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: ${({ account }) => (!account ? "20px" : "0")};
  border-bottom-left-radius: ${({ account }) => (!account ? "20px" : "0")};
`;

export const ButtonText = styled(TYPE.white)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
  `};
`;

export const TwitterButton = styled(ButtonBasic)`
  padding: 6px 12px;
  white-space: nowrap;
  width: 100%;
`;

export const TwitterLogo = styled.img`
  height: 24px;
  width: 24px;
`;

export const RoundedProfileImage = styled.div`
  height: 48px;
  width: 48px;
  background: ${({ theme }) => theme.bg4};
  border-radius: 50%;
  margin-right: 16px;

  & > img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
  }
`;

export const Above1080Only = styled.div`
  display: initial;
  @media (max-width: 1080px) {
    display: none;
  }
`;

export const MobileWrapper = styled.div`
  display: none;
  @media (max-width: 1080px) {
    display: initial;
    position: fixed;
    width: 100%;
    bottom: 0;
    background-color: ${({ theme }) => theme.bg2};
    z-index: 2;
    padding: 0.5rem;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
  }
`;

export const WhiteCard = styled.div`
  background: white;
  padding: 10px;
  border-radius: 12px;
`;

export const AirdropGreyBox = styled.div`
  min-width: 25%;
  // width: 120px;
  border-radius: 12px;
  background: ${({ theme }) => theme.bg2};
  height: 38px;
  margin-left: 5px;

  :hover {
    cursor: help;
  }
`;

export const ProfileButton = styled.div`
  color: ${({ theme }) => theme.black};
  cursor: pointer;
  background: ${({ theme }) => theme.bg2};
  text-decoration: none;
  padding: 9px;
  font-size: 14px;
  border-radius: 4px;
  height: 38px;
`;
