export function ago(val: number) {
  val = 0 | Math.max(0, (Date.now() - val) / 1000);
  let unit;
  const length = {
    second: 60,
    minute: 60,
    hour: 24,
    day: 7,
    week: 4.35,
    month: 12,
    year: 10000,
  };
  let result;

  for (unit in length) {
    //@ts-ignore
    result = val % length[unit];
    //@ts-ignore
    if (!(val = 0 | (val / length[unit])))
      return result + " " + (result - 1 ? unit + "s" : unit);
  }
  throw "something is wrong with time/ago";
}
