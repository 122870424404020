import React from "react";
import { ChevronRight } from "react-feather";
import { Link } from "react-router-dom";
import { useActiveCampaign } from "state/campaigns/hooks";
import styled from "styled-components";
import { TYPE } from "../../theme";
import { AutoColumn } from "../Column";
import { RowFixed } from "../Row";

const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ theme }) => theme.text1};

  a {
    color: ${({ theme }) => theme.text1};
    text-decoration: none;
  }
  :hover {
    text-decoration: none;
    cursor: pointer;
  }
`;

export const CardSection = styled(AutoColumn)<{ disabled?: boolean }>`
  padding: 1rem;
  z-index: 1;
  opacity: ${({ disabled }) => disabled && "0.4"};
`;

function Breadcrumb({
  title,
  protocolName,
}: {
  title: string;
  protocolName: string;
}) {
  return (
    <RowFixed>
      <ArrowWrapper style={{ alignItems: "flex-start" }}>
        <Link to={`/campaigns/${protocolName}`}>
          <TYPE.body fontSize={"12px"} color={"#acacac"}>
            Campaigns
          </TYPE.body>
        </Link>
      </ArrowWrapper>
      <ChevronRight size={12} color={"#acacac"} />
      <TYPE.body fontWeight='600' fontSize={"12px"} color={"#232323"}>
        {title}
      </TYPE.body>
    </RowFixed>
  );
}

export default Breadcrumb;
