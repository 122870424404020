import React, { useState, useEffect } from "react";
import Modal from "components/Modal";
import { ButtonPrimarySmall } from "components/referral/GMXReferralModal";
import styled from "styled-components";

const StyledBugModal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  align-items: center;
  justify-content: center;
`;

function BugModal() {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    const bugModalStatus = localStorage.getItem("bugModalStatus");
    if (!bugModalStatus) {
      setIsOpen(true);
      localStorage.setItem("bugModalStatus", "visited");
    }
  }, []);
  return (
    <Modal isOpen={isOpen} onDismiss={() => setIsOpen(false)}>
      <StyledBugModal>
        <h1>GM, GN, Welcome</h1>
        <p>
          We are excited to have you on board! We just launched AmpliFi and if
          you encounter any bugs or have a suggestion to make, feel free to drop
          us a message on our{" "}
          <a style={{ color: "#FE532D" }} href='https://discord.gg/hDAJgMp4YX'>
            Discord
          </a>
          . Your feedback will help us to ship better products in the future.
          Once again, thank you!
        </p>
        <ButtonPrimarySmall onClick={() => setIsOpen(false)}>
          Got it!
        </ButtonPrimarySmall>
      </StyledBugModal>
    </Modal>
  );
}

export default BugModal;
