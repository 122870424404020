import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";
import config from "config";
import possibleTypes from "../possibleTypes.json";

export const cre8rCmsClient = new ApolloClient({
  link: new HttpLink({
    uri: config.api.wp,
  }),
  cache: new InMemoryCache({
    possibleTypes,
  }),
  shouldBatch: true,
});
