import { useWeb3React } from "@web3-react/core";
import { ButtonCustom, ButtonPrimary } from "components/Button";
import Modal from "components/Modal";
import toast from "components/Toast";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { TYPE } from "theme";
import { stringToBytes32 } from "utils/format";
import { useGMXReferralContract } from "web3/gmx";
import TwitterIntentButton from "./TwitterIntentButton";
import { PlusSquare, Type, X } from "react-feather";
import { Web3Provider } from "@ethersproject/providers";
import { CopyBtn } from "components/AccountDetails/Copy";
import { BigNumber, ethers } from "ethers";
import { RowBetween } from "components/Row";
import { AutoColumn } from "components/Column";
import { LoadingView, SubmittedView } from "components/ModalViews";
import { shortenAddress } from "utils";
import { Text } from "rebass";
import config from "config";
import { BiCopy } from "react-icons/bi";
import useDebounce from "hooks/useDebounce";
import useCopyClipboard from "hooks/useCopyClipboard";

const ARBITRUM_CHAIN_ID = 42161;

const Input = styled.input<{ error?: boolean }>`
  font-size: 15px;
  line-height: 20px;
  padding: 20px;
  border: 1px solid red;
  border-radius: 5px;
  flex: 1 1 auto;
  width: 0;
  line-height: 30px;
  background-color: #eaeaea;
  transition: color 300ms ${({ error }) => (error ? "step-end" : "step-start")};
  color: black;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  width: 100%;
  ::placeholder {
    color: ${({ theme }) => theme.text4};
  }
  padding: 0px;
  -webkit-appearance: textfield;

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  ::placeholder {
    color: ${({ theme }) => theme.text4};
  }
`;

export const ButtonPrimarySmall = styled(ButtonPrimary)`
  border-radius: 4px;
  font-size: 12px;
  font-weight: normal;
  width: fit-content;
  &:visited,
  &:focus {
    background-color: ${({ theme }) => theme.primary1};
    outline-style: none;
  }
`;

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 24px;
`;

const StyledClosed = styled(X)`
  :hover {
    cursor: pointer;
  }
`;

//todo error handling
export default function GMXReferralModal() {
  const [isOpen, setIsOpen] = useState(false);
  const gmxContract = useGMXReferralContract(true);
  const { account, library, chainId } = useWeb3React();
  const [code, setCode] = useState<string>("");
  const [isCodeValid, setIsCodeValid] = useState<boolean>(false);
  const [isCodeValidLoading, setIsCodeValidLoading] = useState<boolean>();
  const [registerLoading, setRegisterLoading] = useState<boolean>(false);
  const [registerSuccess, setRegisterSuccess] = useState<boolean>();
  const [skipClaim, setSkipClaim] = useState<boolean>();
  const [transferLoading, setTransferLoading] = useState<boolean>(false);
  const [transferSuccess, setTransferSuccess] = useState<boolean>();
  const debouncedCode = useDebounce(code, 1000);
  const [localCode, setLocalCode] = useState<string>(); // GMX referral code saved in localstorage for twitter intent
  useEffect(() => {
    if (localStorage) {
      const _localCode = localStorage.getItem("GMX code");
      if (_localCode) setLocalCode(_localCode);
    }
  }, [localStorage]);
  const [isCopied, setCopied] = useCopyClipboard();

  const resetState = () => {
    //if the person closes modal and something is loading, don't do anything
    //they will open modal back where they stopped
    //maybe add reset button?
    if (registerLoading || transferLoading) return;
    setCode("");
    setIsCodeValid(false);
    setRegisterSuccess(undefined);
    setSkipClaim(undefined);
    setTransferSuccess(undefined);
  };

  const toastOnError = async (cb: () => Promise<void>) => {
    try {
      await cb(); // this await is important for error handling
    } catch (e: any) {
      // if (e.code === -32603) {
      //   toast.error(e.data.message);
      // }
      toast.error(e.data.message ?? e);
    }
  };

  const checkCodeValid = React.useCallback(
    async (uncheckedCode: string) => {
      if (!gmxContract) return;
      if (!account) return;
      if (!library) return;
      if (chainId !== 42161) return; //requires to be on arbitrum
      toastOnError(async () => {
        setCode(uncheckedCode);
        setIsCodeValid(false);
        setIsCodeValidLoading(true);
        const owner = await gmxContract.codeOwners(
          stringToBytes32(uncheckedCode),
        );
        if (parseInt(owner) === 0) {
          setIsCodeValid(true);
        } else {
          setIsCodeValid(false);
        }
        setIsCodeValidLoading(false);
      });
    },
    [account, chainId, gmxContract, library],
  );

  React.useEffect(() => {
    checkCodeValid(debouncedCode);
  }, [debouncedCode, checkCodeValid]);

  const registerCode = () => {
    if (!isCodeValid || isCodeValidLoading) return;
    if (!gmxContract) return;
    if (!account) return;
    if (!library) return;
    if (chainId !== 42161) return; //requires to be on arbitrum
    if (!code) return;
    toastOnError(async () => {
      //todo: check if code is registered already
      setRegisterLoading(true);
      await gmxContract
        .registerCode(stringToBytes32(code))
        .then((res) => {
          res.wait(1).then(() => {
            setRegisterLoading(false);
            setRegisterSuccess(true);
          });
        })
        .catch(() => {
          setRegisterSuccess(false);
          setRegisterLoading(false);
          toast.error("Couldn't register your code");
        });
    });
  };
  const transferCode = () => {
    console.log(gmxContract, account, library);
    if (!gmxContract) return;
    if (!account) return;
    if (!library) return;
    if (chainId !== 42161) return; //requires to be on arbitrum
    if (!code) return;
    toastOnError(async () => {
      setTransferLoading(true);
      await gmxContract
        .setCodeOwner(
          stringToBytes32(code),
          config.multisigWallets[ARBITRUM_CHAIN_ID],
        )
        .then((res) => {
          res.wait(1).then((f) => {
            setTransferLoading(false);
            setTransferSuccess(true);
            localStorage.setItem("GMX code", code);
            setLocalCode(code);
          });
        });
    });
  };

  const switchNetwork = async () => {
    if (!account || !library || chainId === 42161) return;
    try {
      await library.provider.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: ethers.utils.hexlify(BigNumber.from(ARBITRUM_CHAIN_ID)),
          },
        ],
      });
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if ((switchError as any).code === 4902) {
        try {
          await library.provider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: ethers.utils.hexlify(
                  BigNumber.from(ARBITRUM_CHAIN_ID),
                ),
                chainName: "Arbitrum One",
                rpcUrls: ["https://arb1.arbitrum.io/rpc"],
                blockExplorerUrls: ["https://arbiscan.io/"],
                nativeCurrency: {
                  name: "ETH",
                  symbol: "ETH",
                  decimals: 18,
                },
              },
            ],
          });
        } catch (e) {
          console.log(e);
        }
      }
    }
  };

  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsCodeValid(false);
    setIsCodeValidLoading(true);
    setCode(e.target.value);
  };

  return (
    <>
      <div style={{ display: "flex", gap: 12 }}>
        {!localCode ? (
          <ButtonPrimarySmall
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            Generate Referral Code &nbsp; <PlusSquare size={20} />
          </ButtonPrimarySmall>
        ) : (
          <ButtonPrimarySmall
            style={{
              minWidth: "150px",
              minHeight: "40px",
            }}
            onClick={() => {
              setCopied(`https://app.gmx.io/#/?ref=${localCode}`);
            }}
          >
            {!isCopied ? (
              <>
                Copy Referral Link: {localCode} &nbsp; <BiCopy size={20} />
              </>
            ) : (
              "Copied"
            )}
          </ButtonPrimarySmall>
        )}
        <TwitterIntentButton
          overrideReferralLink={`https://app.gmx.io/#/?ref=${localCode}`}
        />
      </div>
      <Modal
        isOpen={isOpen}
        onDismiss={() => {
          setIsOpen(false);
          resetState();
        }}
      >
        <AutoColumn gap='lg'>
          <ContentWrapper gap='lg'>
            {chainId !== ARBITRUM_CHAIN_ID ? (
              <AutoColumn gap='lg'>
                <RowBetween>
                  <TYPE.mediumHeader fontWeight={500}>
                    Switch to Arbitrum
                  </TYPE.mediumHeader>

                  <StyledClosed
                    stroke='black'
                    onClick={() => setIsOpen(false)}
                  />
                </RowBetween>
                <TYPE.body>
                  GMX is on Arbitrum, please switch to Arbitrum in order to
                  start the referral process.
                </TYPE.body>
                <ButtonPrimary
                  onClick={() => {
                    if (!account) {
                      toast.error("Please connect wallet");
                      return;
                    }
                    switchNetwork();
                  }}
                >
                  <TYPE.mediumHeader color='white'>
                    Switch network
                  </TYPE.mediumHeader>
                </ButtonPrimary>
              </AutoColumn>
            ) : (
              <>
                {!registerLoading && !skipClaim && !registerSuccess && (
                  <AutoColumn gap='lg' justify='center'>
                    <RowBetween>
                      <TYPE.mediumHeader fontWeight={500}>
                        GMX Referral Generation
                      </TYPE.mediumHeader>
                      <StyledClosed
                        stroke='black'
                        onClick={() => {
                          setIsOpen(false);
                          resetState();
                        }}
                      />
                    </RowBetween>
                    <TYPE.body>
                      Step 1 of 2: Enter a referral code that you already own or
                      want.
                    </TYPE.body>
                    <Input
                      className='recipient-address-input'
                      type='text'
                      autoComplete='off'
                      autoCorrect='off'
                      autoCapitalize='off'
                      spellCheck='false'
                      placeholder='Ex: AmpliFi'
                      // error={""}
                      //TODO: referral codes should be regexed appropriately as the same as the GMX app , "Only letters, numbers and underscores are allowed."
                      pattern='^(0x[a-fA-F0-9]{40})$'
                      // onChange={checkCodeValid}
                      onChange={handleCodeChange}
                      value={code}
                      style={{
                        padding: 5,
                        paddingLeft: 10,
                        border: "none",
                      }}
                    />

                    <ButtonPrimary
                      altDisabledStyle
                      disabled={
                        !isCodeValid ||
                        isCodeValidLoading ||
                        debouncedCode === ""
                      }
                      onClick={registerCode}
                    >
                      <TYPE.mediumHeader color='white'>
                        {!isCodeValid && !isCodeValidLoading && debouncedCode
                          ? `This code is already taken`
                          : "Claim referral code"}
                      </TYPE.mediumHeader>
                    </ButtonPrimary>
                  </AutoColumn>
                )}
                {registerLoading && (
                  <LoadingView
                    onDismiss={() => {
                      setIsOpen(false);
                      resetState();
                    }}
                  >
                    <AutoColumn gap='12px' justify={"center"}>
                      <TYPE.largeHeader>
                        Claiming your referral code
                      </TYPE.largeHeader>
                      <TYPE.main fontSize={36}>{code}</TYPE.main>
                    </AutoColumn>
                  </LoadingView>
                )}
                {(skipClaim || registerSuccess) &&
                  !transferSuccess &&
                  !transferLoading && (
                    <>
                      <AutoColumn gap='lg' justify='center'>
                        <RowBetween>
                          <TYPE.mediumHeader fontWeight={500}>
                            GMX Referral Generation
                          </TYPE.mediumHeader>
                          <StyledClosed
                            stroke='black'
                            onClick={() => {
                              setIsOpen(false);
                              resetState();
                            }}
                          />
                        </RowBetween>
                        <TYPE.body>
                          Step 2: Transfer code{" "}
                          <span style={{ fontWeight: "bold" }}>{code}</span> to
                          AmpliFi. Fees sent to AmpliFi plus bonus incentives
                          will be sent to {shortenAddress(account || "")}.
                        </TYPE.body>
                        <TYPE.body></TYPE.body>
                        <ButtonPrimary
                          disabled={!isCodeValid}
                          onClick={transferCode}
                        >
                          <TYPE.mediumHeader color='white'>
                            Transfer referral code
                          </TYPE.mediumHeader>
                        </ButtonPrimary>
                      </AutoColumn>
                    </>
                  )}
                {transferLoading && (
                  <LoadingView
                    onDismiss={() => {
                      setIsOpen(false);
                      resetState();
                    }}
                  >
                    <AutoColumn gap='12px' justify={"center"}>
                      <TYPE.largeHeader>
                        Transfering your referral code
                      </TYPE.largeHeader>
                      <TYPE.main fontSize={36}>{code}</TYPE.main>
                    </AutoColumn>
                  </LoadingView>
                )}
                {transferSuccess && (
                  <SubmittedView
                    onDismiss={() => {
                      setIsOpen(false);
                      resetState();
                    }}
                    hash={""}
                  >
                    <AutoColumn gap='12px' justify={"center"}>
                      <TYPE.largeHeader>Transaction Submitted</TYPE.largeHeader>
                      <CopyBtn toCopy={`https://app.gmx.io/#/?ref=${code}`}>
                        Copy your link
                      </CopyBtn>
                      {/* <TYPE.body textAlign='center'>
                        https://app.gmx.io/#/?ref={code}
                      </TYPE.body> */}
                      <ButtonPrimary
                        onClick={() => {
                          setIsOpen(false);
                          resetState();
                        }}
                        style={{ margin: "20px 0 0 0" }}
                      >
                        <Text fontWeight={500} fontSize={20}>
                          Start sharing to earn
                        </Text>
                      </ButtonPrimary>
                    </AutoColumn>
                  </SubmittedView>
                )}
              </>
            )}
          </ContentWrapper>
        </AutoColumn>
      </Modal>
    </>
  );
}
