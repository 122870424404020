import React, { useState, useEffect } from "react";
import { AutoColumn } from "../Column";
import { useActiveProtocol } from "../../state/governance/hooks";
import { useToggleModal } from "../../state/application/hooks";
import ReactGA from "react-ga";
import { ApplicationModal } from "../../state/application/actions";
import { RowBetween, RowFlat } from "../Row";
import { TYPE } from "../../theme";
import { shortenAddress } from "../../utils";
import { useVerifiedHandle } from "../../state/social/hooks";
import { useTwitterAccount } from "../../state/user/hooks";
import useParsedQueryString from "../../hooks/useParsedQueryString";
import Modal from "../Modal";
import TwitterFlow from "../twitter/TwitterFlow";
import TwitterLoginButton from "../twitter/TwitterLoginButton";
import TwitterIcon from "../../assets/images/Twitter_Logo_Blue.png";
import VoteContent from "./VoteContent";
import WalletSummary from "./WalletSummary";
import MysteryAmplifiCard from "components/MysteryAmplifiCard";
import ChainDropdown from "./ChainDropdown";
import { ProfileTooltip } from "components/Tooltip";
import useOnClickOutside from "hooks/useClickOutside";
import { useWeb3React } from "@web3-react/core";
import {
  WhiteCard,
  SectionWrapper,
  TwitterButton,
  TwitterLogo,
  AirdropGreyBox,
  ProfileButton,
} from "./StyledProfile";

export function ProfilePopUp() {
  const [show, setShow] = useState<boolean>(false);

  const toggle = React.useCallback(() => {
    setShow(!show);
  }, [show]);

  const profileContentRef = React.useRef(null);
  const profileButtonEl = React.useRef(null);

  useOnClickOutside(profileContentRef, (e: Event) => {
    if (e.target === profileButtonEl.current) {
      return;
    } else {
      setShow(false);
    }
  });

  const { account } = useWeb3React();
  const [activeProtocol] = useActiveProtocol();

  // UI views
  const toggleWalletModal = useToggleModal(ApplicationModal.WALLET);

  // toggle modal for twitter verification
  const [showTwitterFlow, setShowTwitterFlow] = useState<boolean>(false);

  // get any verified handles for this user + timestamps they were created at
  const [twitterAccount] = useTwitterAccount(); // logged in account
  const verifiedHandleEntry = useVerifiedHandle(account);

  const loadingVerifiedHandles = verifiedHandleEntry === null;

  // on redirect from twitter, if signed in, not verified, and no loading, show modal
  const [loaded, setLoaded] = useState(false);
  const { username: usernameQuery } = useParsedQueryString();
  useEffect(() => {
    if (
      twitterAccount &&
      !verifiedHandleEntry &&
      !loadingVerifiedHandles &&
      !loaded &&
      usernameQuery
    ) {
      setShowTwitterFlow(true);
      setLoaded(true);
    }
  }, [
    loadingVerifiedHandles,
    twitterAccount,
    loaded,
    usernameQuery,
    verifiedHandleEntry,
    setShowTwitterFlow,
  ]);

  const ProfileContent = () => (
    <>
      <Modal
        isOpen={showTwitterFlow}
        onDismiss={() => setShowTwitterFlow(false)}
      >
        <TwitterFlow onDismiss={() => setShowTwitterFlow(false)} />
      </Modal>
      <SectionWrapper ref={profileContentRef} style={{ width: "330px" }}>
        <AutoColumn gap='16px'>
          {!verifiedHandleEntry && account ? (
            !twitterAccount ? (
              <WhiteCard>
                <RowFlat>
                  <MysteryAmplifiCard width={"140px"} />
                  <div style={{ width: "5px" }} />
                  <TwitterLoginButton text='Add a public identity' />
                </RowFlat>
                Connect your Twitter
              </WhiteCard>
            ) : (
              <TwitterButton
                onClick={() => {
                  ReactGA.event({
                    category: "Social",
                    action: "Start Link",
                    label: "Not linked",
                  });
                  setShowTwitterFlow(true);
                }}
              >
                <RowBetween>
                  <TYPE.white fontSize='14px'>Add a public identity</TYPE.white>
                  <TwitterLogo src={TwitterIcon} />
                </RowBetween>
              </TwitterButton>
            )
          ) : null}
          {account && verifiedHandleEntry && (
            <WhiteCard>
              <RowFlat>
                <MysteryAmplifiCard />
                <AirdropGreyBox />
                <AirdropGreyBox />
              </RowFlat>
            </WhiteCard>
          )}
          <WalletSummary />
          <TYPE.main mb='16px'>
            Your{" "}
            <span style={{ color: activeProtocol?.primaryColor }}>
              {" "}
              {activeProtocol?.name}
            </span>{" "}
            breakdown
          </TYPE.main>
          <VoteContent />
        </AutoColumn>
      </SectionWrapper>
    </>
  );

  return (
    <>
      {account ? (
        <>
          <ProfileTooltip
            Component={ProfileContent}
            show={show}
            placement='bottom'
          >
            <ProfileButton onClick={toggle} ref={profileButtonEl}>
              {shortenAddress(account)}
            </ProfileButton>
          </ProfileTooltip>
          <ChainDropdown />
        </>
      ) : (
        <ProfileButton onClick={() => toggleWalletModal()}>
          Connect wallet
        </ProfileButton>
      )}
    </>
  );
}
