import React from "react";
import LeaderboardList from "components/campaigns/LeaderboardList";
import { RouteComponentProps } from "react-router-dom";
import { AutoColumn } from "../components/Column";
import Dropdown from "../components/governance/Dropdown";
import { WrappedListLogo } from "../components/governance/styled";
import Tabs from "../components/governance/Tabs";
import { RowFixed } from "../components/Row";
import { useProtocolUpdate } from "../hooks/useProtocolUpdate";
import { useActiveProtocol } from "../state/governance/hooks";
import { TYPE } from "../theme";
import { BodyWrapper, LowerBodyWrapper, WhiteHeader } from "./AppBody";

import { Above1080Only, Below1080Only } from "../theme/components";
import TopBar from "components/TopBar";
import DashboardOverview from "components/campaigns/DashboardOverview";

export default function Dashboard({
  match: {
    params: { protocolID },
  },
}: RouteComponentProps<{ protocolID?: string }>) {
  // if valid protocol id passed in, update global active protocol
  useProtocolUpdate(protocolID);

  const [activeProtocol] = useActiveProtocol();

  return (
    <BodyWrapper>
      <AutoColumn gap='1rem'>
        <>
          <AutoColumn gap='sm'>
            <TopBar />
          </AutoColumn>
        </>
        <LowerBodyWrapper>
          <DashboardOverview />
        </LowerBodyWrapper>
      </AutoColumn>
    </BodyWrapper>
  );
}
