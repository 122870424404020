export const formatProtocolID_campaignID_tabID = (
  protocolID: string,
  campaignID: string,
  tabID?: string,
) => {
  if (tabID) {
    return protocolID + "/" + campaignID + "/" + tabID;
  }
  return protocolID + "/" + campaignID;
};
