import { gql } from "@apollo/client";
export const PAYOUT_FRAGMENT = gql`
  fragment PayoutFragment on Reward {
    id
    status

    campaign {
      protocolName
      slug
    }
    tokens {
      amountUSD
      amountNum

      token {
        symbol
        name
        decimals
        address
        chainId
      }
    }
  }
`;
