const config = {
  multisigWallets: {
    42161: "0x6A03fa1df243aBE7A46Cf943dE1B1E500285949c".toLowerCase(),
  },
  rebrandly: {
    id: "e8427f17cf1041c39b96babd02efb4dd",
    fullName: "amplifi.gg", // this purely for cosmetic
  },
  api: {
    main: "https://oyster-app-6fdjz.ondigitalocean.app/graphql",
    wp: "https://cre8r.vip/graphql",
    referralSubgraphs: {
      gmx: "https://api.thegraph.com/subgraphs/name/jonomnom/gmx-referral-testing", //"https://api.thegraph.com/subgraphs/name/gmx-io/gmx-arbitrum-referrals",
      customGmx:
        "https://api.thegraph.com/subgraphs/name/jonomnom/gmx-referral-v2-25036828",
    },
  },
  airdrop: {
    excel: {
      id: "1u8IBLhr3Bk9MUkDquCEq2_q-IE-1KRiVYfo1la4nV_Y",
    },
  },
  campaign: {
    toast: "", //"Campaigns are still in testing phase and are subject to change. Please check back soon."
  },
  protocol: {
    tabs: [
      {
        title: "Overview",
        routePrefix: "/campaigns/",
      },
      // {
      //   title: "My Stats",
      //   routePrefix: "/profile/",
      // },
      {
        title: "Leaderboard",
        routePrefix: "/leaderboard/",
        enabledProtocolIds: ["CRE8R", "GMX", "PIXELS"],
      },
      {
        title: "Dashboard",
        routePrefix: "/dashboard/",
        enabledProtocolIds: ["AMPLIFI"],
      },
      // {
      //   title: 'Delegates',
      //   routePrefix: '/delegates/'
      // },
      // {
      //   title: 'Proposals',
      //   routePrefix: '/proposals/'
      // }
    ],
  },
  claimableTokens: ["0x492798Fb464e77Cb3cDA62b9a2C3c65162db198e"],
};

export default config;
