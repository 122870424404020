import React from "react";

import GMXReferralModal from "./GMXReferralModal";
import ReferralLinks from "./ReferralLinks";

//todo error handling
export default function Referral(props: { campaignID: string }) {
  const { campaignID } = props;
  // did consider if we did want to make this wp is first class
  // probably not because any custom modal will need code changes anyways
  // a case where it makes sense for wp is that there is a need to change
  // the modal/ disable the modal, and doing that in wp could make sense
  if (
    campaignID.includes("gmx-tier") ||
    campaignID.includes("gmx-referral-campaign")
  ) {
    return <GMXReferralModal />;
  }
  return <ReferralLinks />;
}
