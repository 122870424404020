import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";

export const payoutsClient = new ApolloClient({
  link: new HttpLink({
    uri: "https://oyster-app-6fdjz.ondigitalocean.app/graphql",
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
});

export const referralClient = payoutsClient;
