import React from "react";
import { CopyBtn } from "components/AccountDetails/Copy";
import { useActiveWeb3React } from "hooks";
import { useActiveCampaign, useReferralLink } from "state/campaigns/hooks";
import styled from "styled-components";
// gutenberg basic styles
import "@wordpress/block-library/build-style/common.css";
import "@wordpress/block-library/build-style/style.css";
import "@wordpress/block-library/build-style/theme.css";
import { ApplicationModal } from "state/application/actions";
import { useToggleModal } from "state/application/hooks";
import { ButtonBasic } from "components/Button";
import { TYPE } from "theme";
import TwitterIntentButton from "./TwitterIntentButton";
import useTwitterIntent from "hooks/useTwitterIntent";

export const Break = styled.div`
  width: 800px;
  background-color: ${({ theme }) => theme.bg3};
  height: 1px;
  margin: 0;
`;

const LoggedOutCard = styled.div<{ numOfLinks?: number }>`
  font-size: 15px;
  padding: 25px;
  background-color: ${({ theme }) => theme.white};
  border-radius: 12px;
  border: 1px solid;
  border-color: ${({ theme }) => theme.primary1};
  color: ${({ theme }) => theme.primary1};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 15px;
  width: ${({ numOfLinks }) =>
    numOfLinks ? (99 / numOfLinks).toString() + "%" : "100%"};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    flex-gap: 10px;
  `};
`;

const ButtonText = styled(TYPE.white)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
  `};
`;

export default function ReferralLinks() {
  const [activeCampaign] = useActiveCampaign();
  const referralLink = useReferralLink();
  const toggleWalletModal = useToggleModal(ApplicationModal.WALLET);
  const { account } = useActiveWeb3React();
  const twitterIntentUrl = useTwitterIntent();

  return (
    <>
      {!account && (
        <LoggedOutCard>
          <TYPE.blue style={{ paddingBottom: 10 }}>
            To check airdrop and generate referral links you must connect your
            wallet:
          </TYPE.blue>
          <ButtonBasic onClick={toggleWalletModal}>
            <ButtonText>Connect wallet</ButtonText>
          </ButtonBasic>
        </LoggedOutCard>
      )}
      {/* <AutoColumn> */}
      {activeCampaign &&
        (referralLink && account ? (
          <>
            <div
              style={{
                padding: 10,
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <CopyBtn
                toCopy={"https://" + referralLink}
                numOfLinks={twitterIntentUrl ? 2 : 1}
              >
                {activeCampaign && (
                  <div style={{ fontSize: "9px", padding: "2px" }}>
                    {activeCampaign.baseUrl.replace("?", "")}
                  </div>
                )}
              </CopyBtn>
              {twitterIntentUrl && <TwitterIntentButton />}
            </div>
          </>
        ) : (
          <></>
        ))}
      {/* </AutoColumn> */}
    </>
  );
}
