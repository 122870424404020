import React, { useState, useRef } from "react";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import "./style.css";
type Option = { value: string; label: string };
export const MultiSelect = (props: {
  options: Array<Option>;
  defaultOption?: Option;
  value: any;
  onChange: any;
}) => {
  return (
    <>
      <Dropdown
        options={props.options}
        onChange={(e) => {
          props.onChange(e);
        }}
        value={
          props.value ||
          props.defaultOption ||
          props.options[0] || {
            value: "INVALID OPTION",
            label: "INVALID OPTION",
          }
        }
        placeholder='Select an option'
        className='dropdown'
      />
    </>
  );
};

export default MultiSelect;
