import { ApolloClient, ApolloQueryResult } from "@apollo/client";
import { USER_ACTIONS_QUERY, USER_PAYOUT_QUERY } from "apollo/queries";
import { GetUserActionsQuery, Query } from "generated/graphql";

export async function fetchUserPayout(
  payoutClient: ApolloClient<any>,
  address: string
): Promise<ApolloQueryResult<GetUserActionsQuery>> {
  try {
    return payoutClient.query({
      query: USER_ACTIONS_QUERY,
      variables: {
        address: address,
      },
      fetchPolicy: "cache-first",
    });
  } catch (e) {
    return Promise.reject(e);
  }
}
