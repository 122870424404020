import { useQuery } from "@tanstack/react-query";
import { leaderboardMapper, RewardList } from "data/leaderboard";
import { fetchGMXRefCodes } from "data/leaderboard/gmx";
import { useActiveWeb3React } from "hooks";
import { useEffect, useState } from "react";
import { formatProtocolID_campaignID_tabID } from "utils/campaigns";

export function useLeaderboard(
  protocolID: string,
  campaignID: string,
  tabID?: string,
) {
  const protocolID_campaignID = formatProtocolID_campaignID_tabID(
    protocolID,
    campaignID,
    tabID,
  );
  const leaderboard = leaderboardMapper[protocolID_campaignID];
  const { isLoading, isError, data, error } = useQuery(
    ["leaderboard", protocolID_campaignID, leaderboard],
    async () => {
      if (!leaderboard || !leaderboard.fetch) {
        return null;
      }
      return leaderboard
        ?.fetch(leaderboard!.title!, leaderboard!.description!)
        .then((res) => res);
    },
    {
      // enabled: typeof leaderboard?.title === "string", //if there is leaderboard data, then run query
    },
  );
  return { isLoading, isError, data, error };
}

export function useRefCodesStat(protocolID: string, address?: string | null) {
  const { isLoading, isError, data, error } = useQuery(
    ["refCodes", protocolID, address],
    async () => {
      if (!address) return null;
      return await fetchGMXRefCodes("RefCodesWithReward", "", address);
    },
    {
      // enabled: typeof leaderboard?.title === "string", //if there is leaderboard data, then run query
    },
  );
  return { isLoading, isError, data, error };
}
