import toast, { Toaster } from "react-hot-toast";
const notify = (message: string) => toast(message);
const success = (message: string) => toast.success(message);
const error = (message: string) => toast.error(message);
export default {
  notify,
  success,
  error,
  Toaster,
};
