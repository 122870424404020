import { gql } from "@apollo/client";
import * as fragments from "./fragments";
export const USER_PAYOUT_QUERY = gql`
  ${fragments.PAYOUT_FRAGMENT}
  query getUserPayout($address: String!) {
    user(address: $address) {
      address
      rewards {
        ...PayoutFragment
      }
    }
  }
`;

export const USER_ACTIONS_QUERY = gql`
  ${fragments.PAYOUT_FRAGMENT}
  query getUserActions($address: String!) {
    user(address: $address) {
      address
      actions {
        timestamp
        weight
        allowedAction {
          description
          type
          campaign {
            protocolName
            slug
          }
        }
        reward {
          ...PayoutFragment
        }
      }
    }
  }
`;

export const RAW_REFERRAL_QUERY = gql`
  query rawReferrals(
    $skip: Float
    $take: Float
    $where: RawReferralWhereInput
    $orderBy: RawReferralOrderByInput
  ) {
    rawReferrals(skip: $skip, take: $take, where: $where, orderBy: $orderBy) {
      id
      refereeAddress
      referrerAddress
      timestamp
    }
  }
`;

export const REFEREES_QUERY = gql`
  query referees($protocolId: String!, $slug: String!) {
    referees(where: { protocolId: $protocolId, slug: $slug }) {
      address
      referrerAddress
    }
  }
`;
