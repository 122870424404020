import { useWeb3React } from "@web3-react/core";
import { useMemo } from "react";
import { getProviderOrSigner } from "utils";
import { IReferralStorage__factory } from "./factories/IReferralStorage__factory";
import { Web3Provider } from "@ethersproject/providers";
const referralStorageAddress = "0xe6fab3f0c7199b0d34d7fbe83394fc0e0d06e99d";

export const useGMXReferralContract = (withSignerIfPossible = false) => {
  const { account, library } = useWeb3React<Web3Provider>();
  return useMemo(() => {
    if (!library) return;
    const provider = getProviderOrSigner(
      library,
      withSignerIfPossible && account ? account : undefined,
    );
    return IReferralStorage__factory.connect(referralStorageAddress, provider);
  }, [account, library, withSignerIfPossible]);
};
