import React from "react";
import styled from "styled-components";
import { TYPE } from "theme";
import { TokenLogo } from "components/Icons/Icons";
import { Info } from "components/QuestionHelper";
import { IncentivesAndKPIs, Box } from "./typesIncentivesKPIs";
import Loader from "components/Loader";

// wraps kpis, bonus, incentives boxes
const MainWrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.white};
`;

// wraps each box with heading, subheading and boxesWrapper which has styledBoxes inside
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 9px;
  border-radius: 4px;
  border: 0px solid #504e4e;
  /* background-color: #989898; */
  /* background-image: url("https://cre8r.vip/wp-content/uploads/2022/09/GMX-card.png"); */

  flex-grow: 1;
  background-size: cover;
  background-repeat: no-repeat;
`;

// wraps styledBoxes
const BoxesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: space-between;
  flex-basis: 100%;
  /* background-color: #49494b; */
`;

const StyledBox = styled.div`
  color: ${({ theme }) => theme.white};
  display: flex;
  padding: 7px 12px;
  border-radius: 4px;
  border: 1px solid #504e4e;
  white-space: nowrap;
  align-items: center;
  box-sizing: border-box;
  /* background-color: #4040408f; */
  flex-basis: 100%;
  justify-content: space-between;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  white-space: normal;
  `}
`;

const IconAndText = styled.div`
  gap: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #ffffff;
`;

export default function IncentivesKPI(props: {
  data: IncentivesAndKPIs;
  tokenIconsSize?: number;
}) {
  return (
    <MainWrapper>
      <IncentivesORKPIs
        data={props.data.incentives}
        name='incentives'
        tokenIconsSize={props.tokenIconsSize}
      />
      <IncentivesORKPIs
        data={props.data.bonus}
        name='bonus'
        tokenIconsSize={props.tokenIconsSize}
      />
      <IncentivesORKPIs
        data={props.data.kPIs}
        name='KPIs'
        tokenIconsSize={props.tokenIconsSize}
      />
    </MainWrapper>
  );
}

const Boxes = (props: { data: Array<Box>; tokenIconsSize?: number }) => (
  <BoxesWrapper>
    {props.data.map((each, i) => (
      <StyledBox key={i}>
        <IconAndText>
          {each.icon && (
            <TokenLogo
              name={each.icon}
              size={props.tokenIconsSize}
              customURL={
                each.iconImage && each.iconImage.link
                  ? each.iconImage.link
                  : undefined
              }
            />
          )}
          <TYPE.custom color='#0A0A0A' fontSize={13}>
            {each.text}
          </TYPE.custom>
        </IconAndText>
        {each.extraInfo && <Info data={each.extraInfo} />}
      </StyledBox>
    ))}
  </BoxesWrapper>
);

export function IncentivesORKPIs(props: {
  data: Array<Box>;
  name: "incentives" | "KPIs" | "bonus";
  tokenIconsSize?: number;
}) {
  let { tokenIconsSize } = props;
  if (tokenIconsSize === undefined) {
    tokenIconsSize = 13;
  }
  const titles = {
    heading: {
      incentives: "Promoter Earns 💸",
      bonus: "Promoters offer for your audience  🤝",
      KPIs: "Events & Actions & Payout triggers 🎯",
    },
    subheading: {
      incentives: "Promoter incentives",
      bonus: "Incentives for your audience",
      KPIs: "Steps leading to onchain actions that trigger payouts",
    },
  };

  return (
    <Wrapper>
      {/* todo: add color to color scheme */}
      <TYPE.black fontSize={15} fontWeight={600} color={"#0A0A0A"}>
        {titles.heading[props.name]}
      </TYPE.black>
      <TYPE.black fontSize={12} fontWeight={500} color={"#959595"}>
        {titles.subheading[props.name]}
      </TYPE.black>
      <Boxes data={props.data} tokenIconsSize={tokenIconsSize} />
    </Wrapper>
  );
}

const LoadingBox = styled.div`
  flex-grow: 1;
  width: 500px;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export function LoadingIncentivesKPIs() {
  return (
    <MainWrapper>
      <LoadingBox>
        <Loader />
      </LoadingBox>
      <LoadingBox>
        <Loader />
      </LoadingBox>
      <LoadingBox>
        <Loader />
      </LoadingBox>
    </MainWrapper>
  );
}
