import { MenuTreeItem } from "hooks/useWP";

type NavItem = {
  id: string;
  label: string;
  parentId: null | string;
  title: null | string;
  uri: string;
};

export const flatListToHierarchical = (data: Array<NavItem> = []) => {
  const tree: Array<MenuTreeItem> = [];
  const childrenOf: any = {};

  // initalize childrenOf so that there so all keys are arrays and not undefined
  data.forEach((item) => {
    childrenOf[item.id] = [];
    if (item.parentId) {
      childrenOf[item.parentId] = [];
    }
  });

  // populating tree
  data.forEach((item) => {
    const newItem = { ...item };
    const { id, parentId } = newItem;
    // @ts-ignore
    newItem.children = childrenOf[id]; // note that this is a pointer so when new items are added to childrenOf[id], newItem.children will be updated after this is called

    // if not a root, then continue building branches, else add to tree
    if (parentId) {
      childrenOf[parentId].push(newItem);
    } else {
      // @ts-ignore
      tree.push(newItem);
    }
  });
  return tree;
};
