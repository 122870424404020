import { CustomGMXReferralClient, GMXReferralClient } from "apollo/client";
import config from "config";
import { ethers } from "ethers";
import {
  ReferralDataQuery,
  ReferrerCodesGmxQuery,
  ReferrerRewardsGmxQuery,
} from "generated/gmx-referral/graphql";
import {
  REFERRAL_DATA_QUERY,
  REFERRER_CODES_QUERY,
  REFERRER_REWARDS_QUERY,
} from "apollo/queries/gmx-referral";
import { RewardData, RewardList, RewardCodeMapData } from "..";
import { TbBrandDiscord } from "react-icons/tb";
import GMXTier1 from "assets/svg/gmx-tier-1.svg";
import GMXTier2 from "assets/svg/gmx-tier-2.svg";
import GMXTier3 from "assets/svg/gmx-tier-3.svg";
import { WrappedListLogo } from "components/governance/styled";
import React from "react";
import styled from "styled-components";

const TierLevel = styled(WrappedListLogo)`
  width: 20px;
  height: 20px;
  border-radius: 0;
`;

const Tier = (tier: "1" | "2" | "3") => {
  switch (tier) {
    case "3":
      return <TierLevel src={GMXTier3} />;
    case "2":
      return <TierLevel src={GMXTier2} />;
    case "1":
    default:
      return <TierLevel src={GMXTier1} />;
  }
};

const formatDiscountUSD = (discountUsd: string) => {
  return (parseFloat(discountUsd) / 1e30).toString();
};
//TODO: Make snapshots of this data and save it to the database (this should be scheduled on the server where the server directly queries the subgraph)
export async function fetchGMXReferrals(
  title: string,
  description: string,
): Promise<RewardList> {
  const headers = ["Tier", "Traders Referred", "Total Rebates", "$LAGG"];
  const gmxReferralData = await GMXReferralClient.query<ReferralDataQuery>({
    query: REFERRAL_DATA_QUERY,
    variables: {
      typeIds: ["1", "2"],
      account: config.multisigWallets[42161],
      timestamp: parseInt((Date.now() / 1000).toFixed(0)),
      referralTotalStatsId: `total:0:${config.multisigWallets[42161]}`,
    },
  });
  const filteredData = gmxReferralData.data.referralCodes.filter(
    ({ oldOwner }) => oldOwner,
  );
  const oldOwnerToAggregateRewardData = new Map<
    string,
    Omit<RewardData, "formattedRewards">
  >();

  //populates map
  filteredData.forEach((d) => {
    if (!d.oldOwner) {
      throw "oldOwner should be filtered";
    }
    const gmxOfficialData = gmxReferralData.data.referrerTotalStats.find(
      (o) => o.referralCode === d.code,
    );
    let registeredReferralsCount: string, discountUsd;
    const referralCode = d.code;
    if (gmxOfficialData) {
      registeredReferralsCount = gmxOfficialData.registeredReferralsCount;
      discountUsd = formatDiscountUSD(gmxOfficialData.discountUsd);
    } else {
      registeredReferralsCount = "0";
      discountUsd = "0";
    }

    const existingRewardData = oldOwnerToAggregateRewardData.get(d.oldOwner);
    const newRewardData = [
      "1",
      registeredReferralsCount,
      discountUsd,
      (parseFloat(discountUsd) / 2).toString(),
    ]; // note: This is assuming that all users are GMX tier 1
    if (existingRewardData) {
      if (existingRewardData.rewards.length !== headers.length) {
        throw "the initial set of the data for the map is probably wrong, make sure when u set the ().rewards that it is the same length as the header";
      }
      const reducedRewards = newRewardData.map((reward, i) =>
        (
          parseFloat(reward) + parseFloat(existingRewardData.rewards[i])
        ).toString(),
      );
      oldOwnerToAggregateRewardData.set(d.oldOwner, {
        address: d.oldOwner,
        defaultSortValue: reducedRewards[2], //aggregated discountedUsd
        rewards: reducedRewards,
      });
    } else {
      oldOwnerToAggregateRewardData.set(d.oldOwner, {
        address: d.oldOwner,
        defaultSortValue: discountUsd,
        rewards: newRewardData,
      });
    }
  });
  const data = [...oldOwnerToAggregateRewardData].map(([key, value]) => {
    const aggregatedRegisteredReferralsCount = value.rewards[1];
    const aggreatedDiscountUsd = value.rewards[2];
    const aggreatedLAGG = value.rewards[3];
    return {
      address: value.address,
      rewards: value.rewards,
      defaultSortValue: value.defaultSortValue,
      formattedRewards: [
        Tier("1"),
        aggregatedRegisteredReferralsCount,
        "~$" + parseFloat(aggreatedDiscountUsd).toFixed(4),
        "~$" + parseFloat(aggreatedLAGG).toFixed(4),
      ],
    };
  });

  return {
    headers: headers,
    data: data,
    title,
    description,
  };
}

export async function fetchGMXRefCodes(
  title: string,
  description: string,
  account: string,
): Promise<RewardCodeMapData> {
  const headers = ["Code", "Reward"];
  const gmxRefCodesForAccount =
    await GMXReferralClient.query<ReferrerCodesGmxQuery>({
      query: REFERRER_CODES_QUERY,
      variables: {
        multisig: config.multisigWallets[42161],
        referrer: account,
      },
    });
  const codesArray = gmxRefCodesForAccount.data.referralCodes;
  const resMap = new Map<string, number>();
  await Promise.all(
    codesArray.map(async ({ code }) => {
      const gmxRewardForRefCode =
        await GMXReferralClient.query<ReferrerRewardsGmxQuery>({
          query: REFERRER_REWARDS_QUERY,
          variables: {
            referralCode_in: [code],
          },
        });
      resMap.set(
        ethers.utils.parseBytes32String(code),
        gmxRewardForRefCode.data.referrerStats.reduce(
          (sum, each) =>
            (sum += parseFloat(formatDiscountUSD(each.discountUsd))),
          0,
        ),
      );
    }),
  );
  return {
    headers: headers,
    data: resMap,
    title,
    description,
  };
}
