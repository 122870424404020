import { gql } from "@apollo/client";

export const REFERRAL_DATA_QUERY = gql`
  query referralData(
    $typeIds: [BigInt!]
    $account: String!
    $timestamp: BigInt
    $referralTotalStatsId: ID!
  ) {
    distributions(
      first: 1000
      orderBy: timestamp
      orderDirection: desc
      where: { receiver: $account, typeId_in: $typeIds }
    ) {
      receiver
      amount
      typeId
      token
      transactionHash
      timestamp
      __typename
    }
    referrerTotalStats: referrerStats(
      first: 1000
      orderBy: volume
      orderDirection: desc
      where: { period: total, referrer: $account }
    ) {
      referralCode
      volume
      trades
      tradedReferralsCount
      registeredReferralsCount
      totalRebateUsd
      discountUsd
      __typename
    }
    referrerLastDayStats: referrerStats(
      first: 1000
      where: { period: daily, referrer: $account, timestamp: $timestamp }
    ) {
      referralCode
      volume
      trades
      tradedReferralsCount
      registeredReferralsCount
      totalRebateUsd
      discountUsd
      __typename
    }
    referralCodes(first: 1000, where: { owner: $account }) {
      code
      oldOwner
      __typename
    }
    referralTotalStats: referralStat(id: $referralTotalStatsId) {
      volume
      discountUsd
      __typename
    }
  }
`;
// TODO: Create page for just user referral codes
export const REFERRER_REWARDS_QUERY = gql`
  query ReferrerRewardsGMX($referralCode_in: [String!]) {
    referrerStats(where: { referralCode_in: $referralCode_in }) {
      discountUsd
    }
  }
`;

export const REFERRER_CODES_QUERY = gql`
  query ReferrerCodesGMX($multisig: String!, $referrer: String!) {
    referralCodes(
      first: 1000
      where: { owner: $multisig, oldOwner: $referrer }
    ) {
      code
      __typename
    }
  }
`;
