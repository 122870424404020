import {
  fetchBoostedBribesVoterPayoutsRound18,
  fetchBoostedBribesVoterPayoutsTotal,
} from "./boostedbribes";
import { fetchGMXReferrals } from "./gmx";
import { fetchPixelsReferrals } from "./pixels";

// The headers should be ordered from least to most important. For responsiveness, the first value in formattedRewards will be the first to go.
export type RewardList = {
  headers: string[]; // example: ["Total CRE8R Rewards", "Total AGG Rewards"]
  data: RewardData[];
  title: string;
  description: string;
};

export type RewardData = {
  rewards: string[]; // ["1000", "1000"] // this is assumed to be in USD or paid in a token valued at a USD
  formattedRewards: (string | JSX.Element)[]; // ie: ["$0 CRE8R", "$0 AGG"]
  defaultSortValue: string; // assumes that this will be parsed into a float
  address: string;
};

export type RewardCodeMap = Map<string, number>;

export type RewardCodeMapData = {
  headers: string[];
  data: RewardCodeMap;
  title: string;
  description: string;
};

interface LeaderboardMapper {
  [protocolID_campaignID_tabID: string]: {
    title: string;
    description: string;
    fetch: (title: string, description: string) => Promise<RewardList>;
  };
}

// TODO: title, description remove from here and add to leadboard folder instead
const gmxReferralMeta = {
  title: "GMX referrals",
  description: "",
  fetch: fetchGMXReferrals,
};

const pixelsMeta = {
  title: "Pixels Leaderboard",
  description:
    "Valid referrals takes 24 hours after a user have connected their premint.",
  fetch: fetchPixelsReferrals,
};
export const leaderboardMapper: LeaderboardMapper = {
  "GMX/gmx-tier-1": gmxReferralMeta,
  "GMX/gmx-tier-2": gmxReferralMeta,
  "GMX/gmx-tier-3": gmxReferralMeta,
  "CRE8R/beets-boosted-bribes": {
    title: "Top Payouts",
    description:
      "Rewards for beets round 16, 17, and 18. (ipfs://QmdfSVPi4GEh6V5taz6DkJy5G61Kt2iKvZY3R8RVBp5T1B)",
    fetch: fetchBoostedBribesVoterPayoutsTotal,
  },
  "CRE8R/beets-boosted-bribes/beets-boosted-bribes-snapshot-voting-how-to-get-boost":
    {
      title: "Payouts for round 18",
      description:
        "https://raw.githubusercontent.com/CRE8RDAO/booosted-bribes/master/payouts/out/bribe-payouts-46563257.json",
      fetch: fetchBoostedBribesVoterPayoutsRound18,
    },
  "PIXELS/pixel-guild-onboarding": pixelsMeta,
  "PIXELS/pixels-guild": pixelsMeta,
};
