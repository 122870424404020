import { ApolloClient, useQuery as useQueryApollo } from "@apollo/client";
import { useQuery as useQueryTan } from "@tanstack/react-query";
import { RAW_REFERRAL_QUERY } from "apollo/queries";
import {
  HighlightStat,
  RecentActivityData,
  RecentActivityList,
} from "data/dashboard";
import { RewardList } from "data/leaderboard";
import { RawReferralsQuery } from "generated/graphql";
import { useEffect, useState } from "react";
import { isAddress, shortenAddress } from "utils";

import { Network, Alchemy } from "alchemy-sdk";
interface DashboardMapper {
  [protocolID_campaignID_tabID: string]: {
    title: string;
    description: string;
    fetch: (title: string, description: string) => Promise<RewardList>;
  };
}

const leaderboardMapper: DashboardMapper = {
  //   "GMX/gmx-tier-1": gmxReferralMeta,
  //   "GMX/gmx-tier-2": gmxReferralMeta,
  //   "GMX/gmx-tier-3": gmxReferralMeta,
  //   "CRE8R/beets-boosted-bribes": {
  //     title: "Top Payouts",
  //     description:
  //       "Rewards for beets round 16, 17, and 18. (ipfs://QmdfSVPi4GEh6V5taz6DkJy5G61Kt2iKvZY3R8RVBp5T1B)",
  //     fetch: fetchBoostedBribesVoterPayoutsTotal,
  //   },
  //   "CRE8R/beets-boosted-bribes/beets-boosted-bribes-snapshot-voting-how-to-get-boost":
  //     {
  //       title: "Payouts for round 18",
  //       description:
  //         "https://raw.githubusercontent.com/CRE8RDAO/booosted-bribes/master/payouts/out/bribe-payouts-46563257.json",
  //       fetch: fetchBoostedBribesVoterPayoutsRound18,
  //     },
};

// TODO : refactor to use useQuery pattern DONE: Jono please test
export function useDashboard(
  protocolID: string,
  campaignID: string,
  tabID?: string,
) {
  const [cleanData, setCleanData] = useState<RecentActivityList>({
    highlights: [],
    recentActivity: [],
    title: "Dashboard",
    description: "",
  });
  const recentActivity = useQueryApollo<RawReferralsQuery>(RAW_REFERRAL_QUERY, {
    fetchPolicy: "cache-and-network",
    pollInterval: 1000,
    variables: {
      take: 50,
      where: {
        slug: "amplifi-instigators",
      },
      orderBy: { timestamp: "desc" },
    },
    onCompleted: (data) => {
      const recentActivity: Array<RecentActivityData> = [];
      if (data.rawReferrals) {
        for (const rawReferral of data.rawReferrals) {
          const { refereeAddress, referrerAddress, timestamp, id } =
            rawReferral;
          let description = "";
          if (!timestamp) continue;
          if (!refereeAddress || !isAddress(refereeAddress)) continue;
          if (referrerAddress && isAddress(refereeAddress)) {
            description = `${shortenAddress(
              refereeAddress,
            )} was referred by ${shortenAddress(
              referrerAddress,
            )} visiting AmpliFi Instigators`;
          } else if (isAddress(refereeAddress)) {
            description = `${shortenAddress(
              refereeAddress,
            )} visited AmpliFi Instigators.`;
          }
          recentActivity.push({
            id: id,
            description: description,
            icon: "",
            timestamp: parseInt(timestamp),
          });
        }
      }
      // TODO : Implement highlights
      setCleanData((c) => ({
        recentActivity: recentActivity,
        highlights: c.highlights,
        title: c.title,
      }));
    },
  });
  const highlights = useQueryTan(
    [],
    async () => {
      // Optional Config object, but defaults to demo api-key and eth-mainnet.
      const settings = {
        apiKey: process.env.REACT_APP_ETHEREUM_ALCHEMY_KEY, // Replace with your Alchemy API Key.
        network: Network.ETH_MAINNET, // Replace with your network.
      };

      const alchemy = new Alchemy(settings);

      // Print total NFT count returned in the response:
      const res: any = await alchemy.nft.getOwnersForContract(
        "0xf7fce9411a01f7b2dee146d3961aa4a602ea2c80",
      );

      if (res && res.owners) {
        setCleanData((c) => ({
          highlights: [
            {
              icon: "BiWalletAlt",
              formattedStat: res.owners.length || "-",
              stat: res.owners.length || 0,
              title: "Owners",
            },
          ],
          recentActivity: c.recentActivity,
          title: c.title,
          description: c.description,
        }));
      }
      return null;
    },
    {
      refetchInterval: 1000,
    },
  );
  return {
    data: cleanData,
    recentActivity,
    highlights,
    isError: recentActivity.error && highlights.isError,
    error: recentActivity.error && highlights.isError,
    isLoading: recentActivity.loading || highlights.isLoading,
  };
}
