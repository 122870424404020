export interface AirdropList {
  [twitterHandle: string]: number;
}

export function convertExcelResponseToAirdropList(
  excelRes: ExcelResponse,
): AirdropList {
  let _airdropList = {};
  //must follow airdrop layout: https://docs.google.com/spreadsheets/d/1u8IBLhr3Bk9MUkDquCEq2_q-IE-1KRiVYfo1la4nV_Y/edit#gid=0
  excelRes.table.rows.forEach((r) => {
    if (!r || !r.c[0] || !r.c[0].v || !r.c[1]) return;
    _airdropList = {
      ..._airdropList,
      [r.c[0].v]: r.c[1].v,
    };
  });
  return _airdropList;
}

interface ExcelResponse {
  version: string;
  reqId: string;
  status: string;
  sig: string;
  table: {
    cols: Array<{ id: string; label: string; type: string; pattern?: string }>;
    parsedNumHeader: 1;
    rows: Array<ExcelTableRow>;
  };
}

interface ExcelTableRow {
  c: Array<{ v: string | null } | null>;
}
export function fetchListExcel(
  sheetId: string,
  sheetName: string,
  type: string,
  query: string | undefined,
): Promise<ExcelResponse> {
  const base = `https://docs.google.com/spreadsheets/d/${sheetId}/gviz/tq?`;
  const url = `${base}&sheet=${sheetName}&tq=${encodeURIComponent(
    query || "Select *",
  )}`;
  return fetch(url)
    .then((res) => res.text())
    .then((rep) => {
      //Remove additional text and extract only JSON:
      const jsonData = JSON.parse(rep.substring(47).slice(0, -2));
      return jsonData;
    });
}
