import React, { useEffect, useState } from "react";
import { LoadingRows } from "components/Loader";
import { RowBetween } from "components/Row";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import styled from "styled-components";
import { Button, TYPE } from "theme";
import Column, { AutoColumn } from "components/Column";
import { EmptyWrapper } from "components/governance/styled";
import { useCampaign } from "hooks/useCampaign";
import { useDispatch } from "react-redux";
import { AppDispatch } from "state";
import { useActiveProtocol } from "state/governance/hooks";
import { SUPPORTED_PROTOCOLS } from "state/governance/reducer";
import { useWPUriQuery } from "hooks/useWP";
import CampaignCard from "./CampaignCard";
import { ApolloQueryResult } from "@apollo/client";
import { GetNodeByUriQuery } from "generated/wp/graphql";

const Wrapper = styled.div<{ backgroundColor?: string }>`
  width: 100%;
  padding-top: 2rem;
`;

const ResponsiveText = styled(TYPE.black)`
  text-overflow: ellipsis;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
  `};
`;

const ProposalItem = styled.div`
  text-decoration: none;

  margin: 6px;
  box-sizing: border-box;
`;

const LinkStyledText = styled.div`
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.primary1};
  font-weight: 400;

  :hover {
    text-decoration: underline;
  }

  :focus {
    outline: none;
    text-decoration: underline;
  }

  :active {
    text-decoration: none;
  }
`;

export const Break = styled.div`
  width: 100%;
  border-top: 1px solid;
  border-color: ${({ theme }) => theme.bg3};
  margin: 1rem 0;
`;

const ResponsiveImage = styled.img`
  width: 50%;
  /* height: 70%; */

  object-fit: cover;

  background-color: green;
  border-radius: 0 6px 6px 0;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: 100%;
  border-radius: 6px 6px 0 0;
  `};
`;

export const ResponsiveRow = styled(RowBetween)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column-reverse;
  `};
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 12px;
  grid-row-gap: 12px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  grid-template-columns: 1fr;
`};

  ${({ theme }) => theme.mediaWidth.upToDunks`
    grid-template-columns: 1fr 1fr;
`};
`;

function CampaignList({
  match: {
    params: { protocolID },
  },
}: RouteComponentProps<{ protocolID: string }>) {
  // const [activeProtocol, setActiveProtocol] = useActiveProtocol();
  const { amplifiCampaigns, uriToRouteMap } = useCampaign(protocolID);
  const queryUriToContent = useWPUriQuery();
  // if valid protocol id passed in, update global active protocol
  const dispatch = useDispatch<AppDispatch>();
  const [, setActiveProtocol] = useActiveProtocol();

  const [acFields, setACFields] = useState<
    Array<ApolloQueryResult<GetNodeByUriQuery>>
  >([]);
  useEffect(() => {
    if (protocolID && Object.keys(SUPPORTED_PROTOCOLS).includes(protocolID)) {
      setActiveProtocol(SUPPORTED_PROTOCOLS[protocolID]);
    }
  }, [dispatch, protocolID, setActiveProtocol]);

  useEffect(() => {
    if (!amplifiCampaigns) return;
    Promise.allSettled(
      amplifiCampaigns.map((ac) => {
        return queryUriToContent(ac.uri);
      }),
    ).then((res) => {
      const filteredRes = res
        .filter((o) => o.status === "fulfilled")
        .map((o) => o.status === "fulfilled" && o.value);
      setACFields(filteredRes as Array<ApolloQueryResult<GetNodeByUriQuery>>);
    });
  }, [queryUriToContent, amplifiCampaigns]);

  return (
    <Wrapper>
      {amplifiCampaigns && amplifiCampaigns.length === 0 ? (
        <EmptyWrapper>
          <TYPE.mediumHeader style={{ marginBottom: "8px" }}>
            No campaigns found.
          </TYPE.mediumHeader>
          <TYPE.subHeader>
            <i>Please contact CRE8RDAO if you have any inquiries</i>
          </TYPE.subHeader>
        </EmptyWrapper>
      ) : (
        <AutoColumn gap='sm'>
          <Grid>
            {amplifiCampaigns &&
            acFields &&
            acFields.length === amplifiCampaigns.length ? (
              amplifiCampaigns.map((v, i) => {
                return (
                  <CampaignCard
                    acField={acFields[i]}
                    amplifiCampaign={v}
                    key={i}
                    protocolID={protocolID}
                  />
                );
              })
            ) : (
              <LoadingRows style={{ paddingTop: 10 }}>
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
              </LoadingRows>
            )}
          </Grid>
        </AutoColumn>
      )}
    </Wrapper>
  );
}

export default withRouter(CampaignList);
