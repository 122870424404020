import { useWeb3React } from "@web3-react/core";
import React, { useMemo } from "react";
import { NetworkContextName } from "../../constants";
import useENSName from "../../hooks/useENSName";
import {
  isTransactionRecent,
  useAllTransactions,
} from "../../state/transactions/hooks";
import { TransactionDetails } from "../../state/transactions/reducer";

import WalletModal from "../WalletModal";

// we want the latest one to come first, so return negative if a is after b
export function newTransactionsFirst(
  a: TransactionDetails,
  b: TransactionDetails,
) {
  return b.addedTime - a.addedTime;
}

export default function Web3Status() {
  const { active, account } = useWeb3React();
  const contextNetwork = useWeb3React(NetworkContextName);

  const { ENSName } = useENSName(account ?? undefined);

  const allTransactions = useAllTransactions();

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions);
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst);
  }, [allTransactions]);

  const pending = sortedRecentTransactions
    .filter((tx) => !tx.receipt)
    .map((tx) => tx.hash);
  const confirmed = sortedRecentTransactions
    .filter((tx) => tx.receipt)
    .map((tx) => tx.hash);

  if (!contextNetwork.active && !active) {
    return null;
  }

  return (
    <WalletModal
      ENSName={ENSName ?? undefined}
      pendingTransactions={pending}
      confirmedTransactions={confirmed}
    />
  );
}
