import axios from "axios";
import { RewardList } from "..";

export async function fetchBoostedBribesVoterPayoutsTotal(
  title: string,
  description: string,
): Promise<RewardList> {
  const res = await axios.get(
    "https://amplifi.infura-ipfs.io/ipfs/QmdfSVPi4GEh6V5taz6DkJy5G61Kt2iKvZY3R8RVBp5T1B",
  ); // this is the total payouts ipfs
  const list = res.data.data;
  const data = [];
  list.sort((a: any, b: any) => {
    return b.AMPinUSD - a.AMPinUSD;
  });
  for (let i = 0; i < list.length; i++) {
    const rewardCRE8R = list[i].CRE8RinUSD.toFixed(2);
    const rewardAGG = list[i].AMPinUSD.toFixed(2);
    const formattedCRE8R = !rewardCRE8R
      ? "$0 CRE8R"
      : "$" + rewardCRE8R + " CRE8R";
    const formattedAGG = !rewardAGG ? "$0 AGG" : "$" + rewardAGG + " AGG";
    data.push({
      address: list[i].address,
      rewards: ["", rewardCRE8R, rewardAGG],
      defaultSortValue: rewardAGG,
      formattedRewards: ["", formattedCRE8R, formattedAGG],
    });
  }
  return {
    headers: ["", "Total CRE8R Rewards", "Total AGG Rewards"],
    data: data,
    title,
    description,
  };
}

export async function fetchBoostedBribesVoterPayoutsRound18(
  title: string,
  description: string,
): Promise<RewardList> {
  const res = await axios.get(
    "https://raw.githubusercontent.com/CRE8RDAO/booosted-bribes/master/payouts/out/bribe-payouts-46563257.json",
  ); // this is the total payouts ipfs
  const list = res.data;
  const data = [];
  list.sort((a: any, b: any) => {
    return b.basicBoost2AmpInUSD - a.basicBoost2AmpInUSD;
  });
  for (let i = 0; i < list.length; i++) {
    const rewardCRE8R = list[i].payoutUSD.toFixed(2);
    const rewardAGG = list[i].basicBoost2AmpInUSD.toFixed(2);
    const formattedCRE8R = !rewardCRE8R
      ? "$0 CRE8R"
      : "$" + rewardCRE8R + " CRE8R";
    const formattedAGG = !rewardAGG ? "$0 AGG" : "$" + rewardAGG + " AGG";
    data.push({
      rewards: ["", rewardCRE8R, rewardAGG],
      defaultSortValue: rewardAGG,
      formattedRewards: ["", formattedCRE8R, formattedAGG],
      address: list[i].address,
    });
  }
  return {
    headers: ["", "Total CRE8R Rewards", "Total AGG Rewards"],
    data: data,
    title,
    description,
  };
}
