import React from "react";
import styled from "styled-components";
import { TYPE } from "theme";
import { Row } from "components/Row";
import Column from "components/Column";
import { TokenLogo, HighlightIcon } from "components/Icons/Icons";
import {
  Money,
  Calendar,
  Social,
  Custom,
  IncomingHighlight,
} from "../campaigns/typesIncentivesKPIs";

import { Info } from "components/QuestionHelper";

const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2px;
  /* background-color: #fe3700; */
  min-height: 68px;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  flex-direction: column;
  `}
`;

export default function HighlightBox({
  data,
  iconColor = "black",
  iconSize = 20,
  tokenIconsSize = 13,
  showInfo = true,
}: {
  data: IncomingHighlight;
  iconColor?: "black" | "white";
  iconSize?: number;
  tokenIconsSize?: number;
  showInfo?: boolean;
}) {
  switch (data.type) {
    case "money":
      return (
        <BoxContainer>
          <HighlightIcon
            type='money'
            iconColor={iconColor}
            iconSize={iconSize}
          />
          <Column style={{ gap: "2px" }}>
            <Row
              gap='7px'
              justify='center'
              style={{
                flexWrap: "wrap",
              }}
            >
              <TYPE.white
                fontWeight={500}
                fontSize={12}
                color={iconColor}
                style={{ whiteSpace: "nowrap" }}
              >
                {(data as Money).mainText}
              </TYPE.white>
              <div style={{ display: "flex", gap: "2px" }}>
                {(data as Money).payoutTokens.map((eachToken) => (
                  <TokenLogo
                    name={eachToken}
                    key={eachToken}
                    customURL={
                      ((data as Money).iconImage?.image &&
                        (data as Money).iconImage?.image.link) ??
                      undefined
                    }
                    size={tokenIconsSize}
                  />
                ))}
              </div>
            </Row>
            <Row>
              <TYPE.custom fontWeight={500} fontSize='10px' color={iconColor}>
                {data.subText}
              </TYPE.custom>
            </Row>
          </Column>
        </BoxContainer>
      );
    case "calendar":
      return (
        <BoxContainer>
          <HighlightIcon
            type='calendar'
            iconColor={iconColor}
            iconSize={iconSize}
          />
          <Column style={{ gap: "2px" }}>
            <Row gap='7px' justify={"center"}>
              <TYPE.white fontWeight={500} fontSize={12} color={iconColor}>
                {(data as Calendar).mainText}
              </TYPE.white>
              {data.extraInfo && showInfo && (
                <Info data={data.extraInfo} iconColor={iconColor} />
              )}
            </Row>
            <Row>
              <TYPE.custom fontWeight={500} fontSize='10px' color={iconColor}>
                {data.subText}
              </TYPE.custom>
            </Row>
          </Column>
        </BoxContainer>
      );
    case "social":
      return (
        <BoxContainer>
          <HighlightIcon
            type='social'
            iconColor={iconColor}
            iconSize={iconSize}
          />
          <Column style={{ gap: "2px" }}>
            <Row gap='8px' justify={"center"}>
              {(data as Social).socials &&
                (data as Social).socials.map((each) => (
                  <a
                    href={each.link}
                    target='_blank'
                    rel='noreferrer'
                    key={each.link}
                  >
                    <TokenLogo name='custom' customURL={each.image.link} />
                  </a>
                ))}
            </Row>
            <Row>
              <TYPE.custom fontWeight={500} fontSize='10px' color={iconColor}>
                {data.subText}
              </TYPE.custom>
            </Row>
          </Column>
        </BoxContainer>
      );
    case "custom":
      return (
        <BoxContainer>
          <img
            style={{
              width: iconSize,
              height: iconSize,
              filter:
                (data as Custom).iconImage.chooseColor !== "showColor" &&
                iconColor === "black"
                  ? "brightness(0%)"
                  : "brightness(100%)",
            }}
            src={
              ((data as Custom).iconImage.image &&
                (data as Custom).iconImage.image.link) ??
              undefined
            }
          />
          <Column style={{ gap: "2px" }}>
            <Row gap='7px' justify={"center"}>
              <TYPE.white fontWeight={500} fontSize={12} color={iconColor}>
                {(data as Custom).mainText}
              </TYPE.white>
              <TYPE.white fontWeight={500} fontSize={12}>
                {(data as Custom).extraInfo && showInfo && (
                  <Info data={data.extraInfo || ""} iconColor={iconColor} />
                )}
              </TYPE.white>
            </Row>
            <Row>
              <TYPE.custom fontWeight={500} fontSize='10px' color={iconColor}>
                {(data as Custom).subText}
              </TYPE.custom>
            </Row>
          </Column>
        </BoxContainer>
      );
    default:
      return null;
  }
}
