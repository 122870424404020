import React from "react";
import {
  CHAIN_NAMES_MAP,
  switchNetwork,
  getObjKey,
  getChainName,
} from "../../connectors/utils";
import Dropdown from "react-dropdown";
import { useWeb3React } from "@web3-react/core";

export default function ChainDropdown() {
  const { chainId } = useWeb3React();
  //TODO hold chainId in state or localStorage
  const [dropDownValue, setDropDownValue] = React.useState<string>();
  React.useEffect(() => {
    setDropDownValue(getChainName(chainId));
  }, [chainId]);

  const handleNetworkSelect = React.useCallback(
    async (value: string) => {
      setDropDownValue(value);
      if (value === getChainName(chainId)) {
        return;
      }
      if (getObjKey(CHAIN_NAMES_MAP, value)) {
        const switchingId = +getObjKey(CHAIN_NAMES_MAP, value)!;
        const result = await switchNetwork(switchingId);
        if (result === "user rejected") {
          console.log(chainId, getChainName(chainId));
          setDropDownValue(getChainName(chainId));
        }
      }
    },
    [chainId],
  );

  return (
    <Dropdown
      options={Object.values(CHAIN_NAMES_MAP)}
      value={dropDownValue ?? "Supported chains"}
      onChange={(e) => handleNetworkSelect(e.value)}
    />
  );
}
